#UserAgent
_ua = do ->
  IE = document.uniqueID
  ltIE6 = typeof window.addEventListener is undefined and typeof document.documentElement.style.maxHeight is undefined
  ltIE7 = typeof window.addEventListener is undefined and typeof document.querySelectorAll is undefined
  ltIE8 = typeof window.addEventListener is undefined and typeof document.getElementsByClassName is undefined
  ltIE9 = IE and typeof window.Worker is undefined
  IE6 = IE and ltIE6
  IE7 = IE and ltIE7 and !ltIE6
  IE8 = IE and ltIE8 and !ltIE7 and !ltIE6
  IE9 = IE and ltIE9 and !ltIE8 and !ltIE7 and !ltIE6
  IE10 = IE and !ltIE9 and !ltIE8 and !ltIE7 and !ltIE6
  Webkit = !document.uniqueID and !window.opera and !window.sidebar and !window.orientation and window.localStorage
  Safari = Webkit and navigator.vendor.search(/apple/i) isnt -1
  Chrome = Webkit and navigator.vendor.search(/google/i) isnt -1

  return {
    IE:IE,
    ltIE6:ltIE6,
    ltIE7:ltIE7,
    ltIE8:ltIE8,
    ltIE9:ltIE9,
    IE6:IE6,
    IE7:IE7,
    IE8:IE8,
    IE9:IE9,
    IE10:IE10,
    Firefox:window.sidebar,
    Opera:window.opera,
    Webkit:Webkit,
    Safari:Safari,
    Chrome:Chrome,
    Mobile:window.orientation
  }

#URL
url = do ->
  href = location.href.split '/'

  localRegex = /^\d+\.\d+\.\d+\.\d+/
  workRegex = /^.*\/pc\/[^\/]+\/.*$/

  for val , i in href
    if val == '' or i == href.length - 1 and val.indexOf '.'
      href.splice(i,1)

  if localRegex.test(location.hostname) is true or location.hostname.indexOf('localhost') isnt -1
    length = 2

  else if workRegex.test(location.href) is true
    length = 3

  else
    length = 1

  path = ''

  for j in [0..(length)]
    path += href[j]

    if j == 0
      path += '//'

    else
      path += '/'

  return path

#スムーススクロール関数モーション定義
jQuery.extend(
  jQuery.easing,
    easeInOutCirc:
      (x, t, b, c, d) ->
        if (t/=d/2) < 1
          return -c/2 * (Math.sqrt(1 - t*t) - 1) + b
        c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b
)

window.onpageshow = (evt) ->
  if evt.persisted
    location.reload()
  return

event =
  all: 'mousedown touchstart pointerdown mouseenter mouseleave'
  type: ''
  start: ''
  enter: ''
  leave: ''
  move: ''
  end: ''
# イベント判定

event.check = (e) ->
  _default = e.type
  event.start = _default
  event.move = if _default == 'pointerdown' then 'pointermove' else if _default == 'touchstart' then 'touchmove' else 'mousemove'
  event.end = if _default == 'pointerdown' then 'pointerup' else if _default == 'touchstart' then 'touchend' else 'click'
  event.enter = if _default == 'pointerenter' then _default else if _default == 'touchenter' then _default else 'mouseenter'
  event.leave = if _default == 'pointerleave' then _default else if _default == 'touchleave' then _default else 'mouseleave'
  return

event.on = (type, elm, fn, delegate) ->
  $(document).off(event.all, elm).on event.all, elm, (e) ->
    e.preventDefault()
    event.check e
    if fn != null
      if e.type == event.enter or e.type == event.leave
        # mouseenter mouseleaveのとき、コールバック関数を即実行
        fn this, e
      else
        if type == 'bind'
          # jQuery $(element).on(event, func);
          $(elm).off(event.end).on event.end, (e) ->
            fn this, e
            return
        else if type == 'live'
          # jQuery $(document).on(event, element, func);
          $(document).off(event.end, elm).on event.end, elm, (e) ->
            fn this, e
            return
        else if type == 'delegate'
          # jQuery $(parentsElement).on(event, element, func);
          $(delegate).off(event.end, elm).on event.end, elm, (e) ->
            fn this, e
            return
    else
      # 引数にコールバック関数が定義されていないときの処理
    return
  return


$ ->
  $id = $('body').attr('id')
  $class = $('body').attr('class')
  if $class.indexOf('mail') != 0

    $ ->
      firstDownFlg = true
      firstUpFlg = false
      # $('#header').headroom
      #   tolerance:
      #     up:0
      #     down:5
      #   offset:0
      #   classes:
      #     initial: 'animated'
      #     pinned: 'slideDown'
      #     unpinned: 'slideUp'
      #   onPin: ->
      #     firstUpFlg = true
      #     if !firstDownFlg
      #       return $('#header').removeClass('slideUpFirst')
      #     return
      #   onUnpin: ->
      #   onTop: ->
      #     firstDownFlg = true
      #     $('#header').removeClass 'slideDownFirst slideDown pin outreturn up'
      #   onNotTop: ->
      #     if firstDownFlg
      #       $('#header').addClass 'slideUpFirst'
      #       return firstDownFlg = false
      $(window).scroll ->
        if firstUpFlg and $(this).scrollTop() <= $('#header').height()
        else if  firstUpFlg
          $('#header').addClass 'outreturn'
          return firstUpFlg = false
        if $(this).scrollTop() <= $('#header').height()+40
          $('#header').addClass 'pin'
        else $('#header').removeClass 'pin'
        if $(this).scrollTop() >= $('#header').height()
          $('#header.slideDown').addClass 'up'
      return

    $ ->
      $('.right-menu').slideMenu
        main_contents: '#container'
      return

    $ ->
      $('.h-menu').bind 'touchstart', ->
        $(".nav").addClass 'active'
        $(".contact-menu").removeClass 'active'
        return
      return

    $ ->
      $('.h-telephone').bind 'touchstart', ->
        $(".contact-menu").addClass 'active'
        $(".nav").removeClass 'active'
        return
      return

    $ ->
      scrollTop = undefined
      $('.h-icon a').bind 'touchend', ->
        scrollTop = $(window).scrollTop()
        $('#container').addClass('noscroll').css 'top', -scrollTop + 'px'
        $('#header').addClass 'after'
        return
      $('.slide-close').bind 'touchstart', ->
        $('#container').addClass 'show'
        $(window).scrollTop scrollTop
        return
      $('.slide-close').bind 'touchend', ->
        $('#container').removeClass 'noscroll show'
        $('#header').addClass 'up outreturn slideDown'
        setTimeout (->
          $('#header').removeClass 'after slideUpFirst slideUp'
          return
        ), 50
        $(window).scrollTop scrollTop
      return

  return

$ ->
  hrefDisabled = (e) ->
    e.preventDefault()
    return

  $ ->
    $('.h-icon a').click ->
      $('#container a[href]').bind 'click', hrefDisabled
      return
    $('.slide-close a.right-menu').bind 'touchend', ->
      setTimeout (->
        $('#container a[href]').unbind 'click', hrefDisabled
        return
      ), 700
      return
    # click
    return

$ ->
  $('.banner-slide-list').slick
    autoplay: false
    centerMode: true
    slidesToShow: 1
    speed: 1000
    dots: true
    centerPadding:'20px'

  topBtn = $("#pagetop")

  #最初はボタンを隠す
  topBtn.hide()

  #スクロールが300に達したらボタンを表示させる
  $(window).scroll ->
    if $(this).scrollTop() > 300
      topBtn.fadeIn()
      $("#pagetop").css
        position: "fixed"
        right: "8px"
        zIndex: "1000"

    else
      topBtn.fadeOut()
    return

  topBtn.click ->
    $("body,html").animate
      scrollTop: 0
    , 500
    false

  return

$ ->
  $("a.menu2,.nav-close2").click ->
    $(".drawer2").toggleClass "active"
    $(".drawer2").slideToggle()
    false

    $("a.menu2").click ->
    $("body,html").animate
      scrollTop: 0
    , 500
    false

  return

$ ->
  $('.btn').bind 'touchstart', ->
    $(this).addClass 'clickstyle'
    return
  $('.btn').bind 'touchend', ->
    $(this).removeClass 'clickstyle'
    return
  return

$ ->
  $(".slide-menu .nav-title").bind 'touchend', ->
    if $("+.nav-detail", this).css("display") is "none"
      $(this).parent().addClass "is-open"
      $("+.nav-detail", this).slideDown "normal"
    else
      $(this).parent().removeClass "is-open"
      $("+.nav-detail", this).slideUp "normaal"
      return

    return

  return

$ ->
  $(".nav-box").each ->
    accordion = $(this)
    $(this).find(".nav-title").click ->
      targetContentWrap = $(this).next(".nav-detail")
      if targetContentWrap.css("display") is "none"
        accordion.find(".nav-detail").fadeOut("fast")
        accordion.find(".nav-title").parent("dl").removeClass "is-open"
      targetContentWrap.fadeToggle("fast")
      $(this).parent("dl").toggleClass "is-open"
      return

    return

  return

$ ->
  topBtn = $('#page-top')
  topBtn.hide()
  #スクロールが100に達したらボタン表示
  $(window).scroll ->
    if $(this).scrollTop() > 100
      topBtn.fadeIn()
    else
      topBtn.fadeOut()
    return
  #スクロールしてトップ
  topBtn.click ->
    $('body,html').animate { scrollTop: 0 }, 500
    false
  return

$ ->
  $("#footer .tokyo dt").addClass("is-open")
  $("#footer .clinic-info").each ->
    accordion = $(this)
    $(this).find(".clinic-item dt").click ->
      targetContentWrap = $(this).next("dd")
      if targetContentWrap.css("display") is "none"
        accordion.find("dd").slideUp()
        accordion.find(".clinic-item dt.is-open").removeClass "is-open"
      targetContentWrap.slideToggle()
      $(this).toggleClass "is-open"
      return

    return

  return

$ ->
  $(".toggle").click ->
    if $("+.detail", this).css("display") is "none"
      $(this).addClass "is-open"
      $("+.detail", this).slideDown "normal"
    else
      $(this).removeClass "is-open"
      $("+.detail", this).slideUp "normal"
      return

    return

  return

  